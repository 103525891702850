// Abstracts
@use "abstracts/index" as *;

$mbsc-ios-accent: light-color(primary-300);
$mbsc-ios-background: light-color(base-000);
$mbsc-ios-text: light-color(base-700);

$mbsc-ios-dark-accent: dark-color(primary-300);
$mbsc-ios-dark-background: dark-color(base-000);
$mbsc-ios-dark-text: dark-color(base-700);

/* Base colors */
$mbsc-ios-primary: light-color(primary-300);
$mbsc-ios-secondary: light-color(base-500);
$mbsc-ios-success: light-color(success-300);
$mbsc-ios-danger: light-color(error-300);
$mbsc-ios-warning: light-color(warning-300);
$mbsc-ios-info: light-color(primary-100);
$mbsc-ios-light: light-color(base-700);
$mbsc-ios-dark: dark-color(base-700);;

$mbsc-ios-error: light-color(error-300);;

@use '@mobiscroll/javascript/dist/css/mobiscroll';
